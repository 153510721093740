const archiveData = [
    {
        title: 'I AM THE CONTROLLER',
        description: 'a virtual theremin, controlled with your webcam',
        liveLink: 'https://iamthecontroller.glitch.me/',
        sourceLink: 'https://github.com/amara-j/I-am-the-controller',
    },
    {
        title: 'MUSICAL WINDOWS',
        description: 'have fun with colors, synths, and scales',
        liveLink: 'https://musical-windows.glitch.me/',
        sourceLink: 'https://github.com/jaqarrick/musical-windows',
    },
    {
        title: 'ENDLESS SEQUENCE',
        description: 'create an infinite melody',
        liveLink: 'https://endless-sequence.glitch.me/',
        sourceLink: 'https://github.com/jaqarrick/endless-sequence/tree/master',
    },
    {
        title: 'WEBRTC INFO',
        description: 'what ~is~ real time communication?',
        liveLink: 'https://github.com/jaqarrick/webrtc-info',
        sourceLink: 'https://github.com/jaqarrick/webrtc-info',
    },
    {
        title: 'YJS LAB',
        description: 'dive deep into this popular crdt implementation',
        liveLink: 'https://github.com/jaqarrick/yjs-lab/',
        sourceLink: 'https://github.com/jaqarrick/yjs-lab/',
    },
    {
        title: 'BIG LINK',
        description: 'url shortener service built with the mern stack',
        liveLink: 'https://biglink.herokuapp.com/',
        sourceLink: 'https://github.com/jaqarrick/biglink-public/',
    },
    {
        title: 'CSCANNER',
        description: 'multi-threaded port scanner',
        liveLink: 'https://github.com/jaqarrick/cscanner',
        sourceLink: 'https://github.com/jaqarrick/cscanner'
    },
    {
        title: 'C-INFO',
        description: 'guide to learning C programming',
        liveLink: 'https://github.com/jaqarrick/c-info',
        sourceLink: 'https://github.com/jaqarrick/c-info',
    },
    {
        title: 'C-SOCKETS',
        description: 'guide to learning socket programming in C',
        liveLink: 'https://github.com/jaqarrick/c-sockets',
        sourceLink: 'https://github.com/jaqarrick/c-sockets',
    }
]

export default archiveData
