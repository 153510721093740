import React, { useCallback, useContext } from 'react'

import { getStyles, styles } from '../SwipapbleSlide/SwipableSlide.styles'
import { css } from 'aphrodite'
import { getHomeStyles } from './Home.styles'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import { LazyLoadImage } from '../LazyLoadImage/LazyLoadImage'
import { Link } from 'react-router-dom'

const githubLink = 'https://github.com/jaqarrick'
const emailLink = 'mailto:mail@jackcarrick.net'
const instagramLink = 'https://instagram.com/known6host'
const cargoLink = 'https://cargo.site'
const weTransferLink = 'https://wetransfer.com'
const recurseLink = 'https://recurse.com'
const linkedInLink = 'https://www.linkedin.com/in/jack-carrick-07b236128/'

export const Home = () => {
    const imageUrl =
        'https://res.cloudinary.com/dcttcffbc/image/upload/v1722643180/home-image.png'
    const { colorTheme, isMobileView, fontsLoaded } = useContext(SiteContext)
    const homeStyles = getHomeStyles(colorTheme, isMobileView)
    const styles = getStyles(colorTheme, isMobileView)

    const [homeImageLoaded, setHomeImageLoaded] = React.useState(false)
    const onImageLoad = useCallback(() => {
        setHomeImageLoaded(true)
    }, [setHomeImageLoaded])
    return (
        <div className={css(styles.slide)}>
            <div className={css(styles.slideInnerContainer)}>
                <div className={css(styles.imageContainer)}>
                    <LazyLoadImage
                        src={imageUrl}
                        alt="Jack Carrick - Portfolio"
                        className={css(
                            isMobileView ? styles.image : homeStyles.homeImage
                        )}
                        onLoaded={onImageLoad}
                    />
                </div>
                <div
                    className={
                        fontsLoaded && homeImageLoaded
                            ? css(homeStyles.homeBodyContainer)
                            : css(
                                  homeStyles.homeBodyContainer,
                                  homeStyles.hidden
                              )
                    }
                >
                    <div className={css(homeStyles.homeBodyTitle)}>
                        <span>sound, strings, software, security</span>
                    </div>
                    {!isMobileView && (
                        <div className={css(homeStyles.homeBodyTitle)}>
                            <span>brooklyn-based engineer</span>
                        </div>
                    )}
                    <div>
                        <div className={css(homeStyles.homeBodyListItem)}>
                            <span className={css(homeStyles.homeBodyPreItem)}>
                                current
                            </span>
                            :{' '}
                            <span>
                                <a
                                    target="__blank"
                                    href={recurseLink}
                                    className={css(styles.link)}
                                >
                                    recurse 
                                </a>
                                {' '} - read my <Link style={{
                                    color: colorTheme.accentColor,
                                    textDecoration: 'unset'
                                }} onClick={e => {
                                    e.stopPropagation()
                                }}  to="/blog">blog</Link> :)
                            </span>
                        </div>
                        <div className={css(homeStyles.homeBodyListItem)}>
                            <span className={css(homeStyles.homeBodyPreItem)}>
                                previous
                            </span>
                            :{' '}
                            <span>
                                <a
                                    target="__blank"
                                    href={weTransferLink}
                                    className={css(styles.link)}
                                >
                                    wetransfer
                                </a>
                                ,{' '}
                                <a
                                    target="__blank"
                                    href={cargoLink}
                                    className={css(styles.link)}
                                >
                                    cargo
                                </a>
                            </span>
                        </div>
                        <div className={css(homeStyles.homeBodyListItem)}>
                            <span className={css(homeStyles.homeBodyPreItem)}>
                                contact
                            </span>
                            :{' '}
                            <span>
                                <a
                                    target="__blank"
                                    href={githubLink}
                                    className={css(styles.link)}
                                >
                                    github
                                </a>
                                ,{' '}
                                <a
                                    target="__blank"
                                    href={emailLink}
                                    className={css(styles.link)}
                                >
                                    email
                                </a>
                                ,{' '}
                                <a
                                    target="__blank"
                                    href={linkedInLink}
                                    className={css(styles.link)}
                                >
                                    linkedin
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
